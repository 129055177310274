<template>
  <navbar-donasi>
    <div class="navbar-brand" @click="$router.push({ path: '/' })">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Donasi Saya
    </h6>
  </navbar-donasi>
  <div v-if="accessToken">
    <div
      class="container filter__category-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="title__category">
        <h6>Status</h6>
      </div>
      <div class="filter__category">
        <select
          id="filter"
          name="filter"
          class="form-select filter__select-category"
          v-model="filterMyDonation"
        >
          <option value="waiting">
            Menunggu
          </option>
          <option value="verifying">
            Proses Verifikasi
          </option>
          <option value="done">
            Berhasil
          </option>
          <option value="failed">
            Gagal
          </option>
        </select>
      </div>
    </div>
    <div v-if="filterDonations.length > 0" class="container donations__wrapper mt-3">
      <router-link class="link__donation" v-for="(donation, index) in filterDonations" :key="index" :to="`/detail-donasi/${donation.transactionCode}`">
        <div class="list__donation">
          <div class="information__donation">
            <div class="donation__wakaf-name">
              {{ donation.wakafName }}
            </div>
            <div class="donation__status">
              <span v-if="donation.transactionStatus === 'waiting'" class="badge bg-warning"
                >Menunggu</span
              >
              <span v-else-if="donation.transactionStatus === 'done'" class="badge bg-success"
                >Berhasil</span
              >
              <span v-else-if="donation.transactionStatus === 'verifying'" class="badge bg-info"
                >Sedang Diverifikasi</span
              >
              <span v-else-if="donation.transactionStatus === 'failed'" class="badge bg-danger"
                >Gagal</span
              >
            </div>
          </div>
          <div class="donation__detail">
              <div class="donation__date">
                <i class="fas fa-calendar" />
                {{
                  $moment(donation.transactionDate)
                    .locale('id')
                    .format('LLLL')
                }}
              </div>
              <div class="transaction__code">
                <i class="fas fa-receipt" />
                {{ donation.transactionCode }}
              </div>
              <div class="transaction__total">
                Rp. {{ $formatPrice(donation.total) }}
              </div>
          </div>
        </div>
        
      </router-link>
    </div>
    <div v-else class="container donations__wrapper mt-3">
      Donasi kosong
    </div>
  </div>
  <div v-else class="container mt-3">
    Untuk melihat data donasi, silahkan login dahulu
  </div>
</template>

<script>
import swal from 'sweetalert2'

import NavbarDonasi from '../components/TransactionComponent/NavbarTransaction.vue'
export default {
  components: { NavbarDonasi },
  data() {
    return {
      accessToken: null,
      filterMyDonation: 'waiting',
      donations: []
    }
  },
  computed: {
    filterDonations() {
      return this.donations.filter(value => value.transactionStatus === this.filterMyDonation)
    }
  },
  mounted() {
    this.isAccessToken()
    this.getMyDonation()
  },
  methods: {
    isAccessToken() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
        }
      }
    },
    getMyDonation() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .get(`${process.env.VUE_APP_API_URL}me/donations`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        })
        .then(response => {
          const datas = response.data.data
          if (datas.length > 0) {
            datas.forEach(value => {
              const objData = {
                transactionStatus: value.transaction_status,
                transactionDate: value.transaction_date,
                transactionCode: value.transaction_code,
                total: value.total,
                wakafName: value.campaign ? value.campaign.wakaf_name : null
              }
              this.donations.push(objData)

              swal.close()
              swal.hideLoading()
            })
            // this.datas.forEach(value => {
            //   // const objData = {
            //   //   transactionId: value.transaction_id,
            //   //   transactionCode: value.transaction_code,
            //   //   transactionStatus: value.transaction_status,
            //   //   transactionDate: value.transaction_date,
            //   //   total: value.total,
            //   //   campaign: {
            //   //     wakafId: value.campaign.wakaf_id,
            //   //     wakafName: value.campaign.wakaf_name
            //   //   }
            //   // }
            //   this.donations.push({
            //     transactionStatus: value.transaction_status,
            //     campaign: { wakafName: value.campaign.wakaf_name }
            //   })
            //   console.log(this.donations)
            // })
          }else{
            swal.close()
            swal.hideLoading()
          }
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
        })
    }
  }
}
</script>
